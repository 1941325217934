<template>
  <div style="padding: 24px;height: 100%"  :style="{background:`url('${bg}')`}">
    <blackList :isComp="true"></blackList>

  </div>
</template>

<script>
import blackList from "@/pages/personality/blackList";

export default {
  components: {
    blackList
  }, data() {
    return {
      bg:require('@/assets/bg_innerImg.jpg')
    }
  },
  name: "MenuBlackList",
}
</script>

<style scoped>

</style>